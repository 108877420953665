import React from 'react';
import {BrowserRouter, useRoutes} from 'react-router-dom';
import FullScreenLayout from "./layouts/FullScreenLayout";
import DemoView from "./views/DemoView";


const AppRouter: React.FC = () => {
    const routes = useRoutes([
        {
            path: '/',
            element: <FullScreenLayout />,
            children: [
                { path: '/', element: <DemoView /> },
            ]
        }
    ])

    return routes
}

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>
    )
}

export default Router;