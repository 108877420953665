import insense from "../assets/models/insense_holder.glb";
import canoe from "../assets/models/Canoe.glb";
import chair from "../assets/models/Chair.glb";
import plant from "../assets/models/GeoPlanter.glb"

export async function fetchItems() {
    return [
        {
            "uid": "GfinsbE",
            "name": "인센스 홀더",
            "glbSrc": insense,
            "thumb": "",
            "seller": {
                "uid": "UcvrQRgs",
                "name": "사이 메탈워크 스튜디오"
            },
            "markets": {
                "11st": "xxx",
                "coupang": "yyy",
                "gmarket": "zzz"
            },
            "categories": [
                "홈", "리빙", "소품"
            ]
        },
        {
            "uid": "Hpdg35jg",
            "name": "카누",
            "glbSrc": canoe,
            "thumb": "",
            "seller": {
                "uid": "UcvrQRgs",
                "name": "사이 메탈워크 스튜디오"
            },
            "markets": {
                "11st": "xxx",
                "coupang": "yyy",
                "gmarket": "zzz"
            },
            "categories": [
                "아웃도어"
            ]
        },
        {
            "uid": "BGk45",
            "name": "의자",
            "glbSrc": chair,
            "thumb": "",
            "seller": {
                "uid": "UcvrQRgs",
                "name": "사이 메탈워크 스튜디오"
            },
            "markets": {
                "11st": "xxx",
                "coupang": "yyy",
                "gmarket": "zzz"
            },
            "categories": [
                "홈", "리빙", "가구"
            ]
        },
        {
            "uid": "GXeksGs",
            "name": "선인장",
            "glbSrc": plant,
            "thumb": "",
            "seller": {
                "uid": "UcvrQRgs",
                "name": "사이 메탈워크 스튜디오"
            },
            "markets": {
                "11st": "xxx",
                "coupang": "yyy",
                "gmarket": "zzz"
            },
            "categories": [
                "홈", "리빙", "원예"
            ]
        }
    ]
}