import React from 'react';
import {
    Container,
    Row,
    Col,
    ListGroup
} from "react-bootstrap";
import ItemContext from "../contexts/ItemContext";
import ARViewer from "../components/ARViewer";


export default function DemoView() {
    const itemContext = React.useContext(ItemContext);
    const {items, selectedItem} = itemContext.store;

    React.useEffect(
        () => {
            itemContext.actions.fetchItems();
        }
        , []
    )

    return (
        <Container>
            <Row>
                <Col lg={6} className="d-none d-sm-block">
                    <ListGroup>
                        {
                            items.map((item) => (
                                <ListGroup.Item
                                    onClick={() => itemContext.actions.setSelectedItem(item)}
                                    active={item.uid === selectedItem.uid}
                                >
                                    <h5>{item.name}</h5>
                                    <div>{item.seller.name}</div>
                                    <small>{item.categories.join(",")}</small>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Col>
                <Col xs={12} lg={6}>
                    {
                        selectedItem && <ARViewer src={selectedItem.glbSrc}/>
                    }
                </Col>

                <Col xs={12} className="d-block d-sm-none">
                    <ListGroup>
                        {
                            items.map((item) => (
                                <ListGroup.Item
                                    onClick={() => itemContext.actions.setSelectedItem(item)}
                                    active={item.uid === selectedItem.uid}
                                >
                                    <h5>{item.name}</h5>
                                    <div>{item.seller.name}</div>
                                    <small>{item.categories.join(",")}</small>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    )
}